<template>
  <div class="row">
    <div class="col-md-4">
      <div class="card card-custom gutter-b example example-compact">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">Basic Information</h3>
          </div>
        </div>
        <div class="card-body">
          <b-form v-if="show">
            <b-form-group label="Id:">
              <b-form-input
                type="text"
                v-model="patient.id"
                disabled
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Study Group:" label-for="input-3">
              <b-form-select
                id="input-3"
                v-model="patient_group"
                disabled
                :options="patient_groups"
              ></b-form-select>
            </b-form-group>
            <b-form-group label="Clinicial Reference:">
              <b-form-input
                type="text"
                v-model="patient.clinical_reference"
                @input="
                  (val) =>
                    (patient.clinical_reference =
                      patient.clinical_reference.toUpperCase())
                "
                required
              ></b-form-input>
            </b-form-group>
            <!-- <b-form-group label="Username/Email:">
              <b-form-input
                type="email"
                v-model="patient.username_email"
              ></b-form-input>
            </b-form-group> -->
            <!-- <b-form-group label="Password:">
              <b-form-input
                type="password"
                v-model="new_password"
                disabled
              ></b-form-input>
            </b-form-group> -->
            <!-- <b-form-group label="Phone:">
              <b-form-input
                type="text"
                v-model="patient.phone"
                placeholder="E.g. +447903001234"
              ></b-form-input>
            </b-form-group> -->
            <!-- <b-form-group label="First Name:">
              <b-form-input
                type="text"
                v-model="patient.firstname"
              ></b-form-input>
            </b-form-group> -->
            <!-- <b-form-group label="Last Name:">
              <b-form-input
                type="text"
                v-model="patient.lastname"
              ></b-form-input>
            </b-form-group> -->
            <b-form-group label="Gender:">
              <b-form-select
                v-model="patient.gender"
                :options="gender_options"
                required
              ></b-form-select>
            </b-form-group>
            <b-form-group v-if="patient.gender == 'Other'">
              <b-form-input
                type="text"
                v-model="patient.meta_data.gender_other_text"
                placeholder="If Other gender, please specify."
                required
              ></b-form-input>
            </b-form-group>
            <!-- <b-form-group label="Birthday Initial Method:">
              <b-form-datepicker v-model="patient.birthday"></b-form-datepicker>
            </b-form-group> -->
            <b-form-group label="Date of birth:" label-for="birthday-input">
              <!-- <b-form-datepicker
                v-model="patient.birthday"
                left
                show-decade-nav
                value-as-date
                locale="en-gb"
                aria-controls="birthday-input"
              ></b-form-datepicker>
              <b-input-group-append> -->
              <Datepicker
                id="birthday-input"
                v-model="patient.birthday"
                placeholder="Select Date"
                typeable
                required
                clear-button
                :bootstrap-styling="true"
                wrapper-class="fullscreen-when-on-mobile"
              ></Datepicker>
              <!-- <b-form-input          
                id="birthday-input"
                v-model="dob"
                type="date"
                required
              >
              </b-form-input> -->
              <!-- </b-input-group-append> -->
            </b-form-group>
            <b-form-group label="Last Activity:">
              <!-- <b-form-input
                type="date"
                v-model="last_updated"
                disabled
              ></b-form-input> -->
              <Datepicker v-model="patient.updatedAt" disabled></Datepicker>
            </b-form-group>
            <b-form-group label="Consents:">
              <b-form-checkbox
                id="checkbox-3"
                v-model="patient.meta_data.single_visit_consent"
                name="checkbox-3"
              >
                Single Visit Consent
              </b-form-checkbox>
              <b-form-checkbox
                id="checkbox-4"
                v-model="patient.meta_data.multiple_visit_consent"
                name="checkbox-4"
              >
                Multiple Visit Consent
              </b-form-checkbox>
              <b-form-checkbox
                id="checkbox-1"
                v-model="patient.research_consent"
                name="checkbox-1"
              >
                Research Consent
              </b-form-checkbox>
              <b-form-checkbox
                id="checkbox-2"
                v-model="patient.privacy_consent"
                name="checkbox-2"
              >
                Privacy Consent
              </b-form-checkbox>
              <!-- <b-form-checkbox id="checkbox-3" v-model="patient.cookie_consent" name="checkbox-3">
                Cookie Consent
              </b-form-checkbox> -->
            </b-form-group>
            <!-- <b-form-group label="Meta Data:">
                <b-form-textarea
                  id="meta-data-json-textfield"
                  v-model="patient.meta_data"
                  :disabled="user.userTypeId != 4"
                  rows="4"
                  max-rows="6"
                ></b-form-textarea>
            </b-form-group> -->
            <b-button @click="onUpdatePatient('main')" variant="primary"
              >Save</b-button
            >
            <b-button
              v-b-modal.modal-delete-patient-confirmation
              variant="danger"
              >Delete</b-button
            >
            <!-- <b-button @click="onResendAccessInfo" variant="info"
              >Resend Access</b-button
            > -->
          </b-form>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <b-card no-body>
        <b-tabs card v-model="activeTab">
          <!-- <b-tab title="Questionnaire Groups">
            <b-card-text>
              <b-table
                hover
                bordered
                :items="patient_entities"
                :fields="table_entities_fields"
                :sort-by.sync="q_sortBy"
                :sort-desc.sync="q_sortDesc"
                @row-clicked="
                  (item, index, event) => entityRowClickHandler(item)
                "
              >
                <template #cell(questionnaire_type)="data">
                  {{ getQuestionnaireTypeText(data.item.questionnaireId) }}
                </template>
                <template #cell(updatedAt)="data">
                  {{ formatDate(data.item.updatedAt) }}
                </template>
                <template #cell(status)="row">
                  <p>{{ statusCheck(row) }}</p>
                </template>
                <template #cell(actions)="row">
                  <b-button
                    v-b-modal.modal-delete-entity-confirmation
                    :disabled="statusCheck(row) != 'Empty'"
                    variant="danger"
                    size="sm"
                    class="mr-2"
                    @click="setRowToDelete(row)"
                    >X</b-button
                  >
                </template>
              </b-table>
              <b-form-group>
                <b-button v-b-modal.modal-create-group variant="primary"
                  >Create New</b-button
                >
                <b-button
                  v-b-modal.modal-auto-generate-questionaire
                  variant="success"
                  >Auto Generate</b-button
                >
              </b-form-group>
            </b-card-text>
          </b-tab> -->
          <!-- Questionnaire -->
          <b-tab title="Visit Data" @click="resetSelectedEntity">
            <p>
              <b>Note:</b> Click the <em>Create New</em> button to add a visit
              data. Subsequently, click on a row in the table to edit/view the
              corresponding record.
            </p>
            <b-card-text>
              <b-table
                hover
                bordered
                :items="filteredPatientAnswers"
                :fields="table_questionnaires_fields"
                :sort-by.sync="q_sortBy"
                :sort-desc.sync="q_sortDesc"
                @row-clicked="
                  (item, index, event) => questionnaireRowClickHandler(item)
                "
              >
                <!-- <template #cell(questionnaire_type)="data">
                  {{ getQuestionnaireTypeText(data.item.questionnaireId) }}
                </template> -->
                <template #cell(symptoms_date)="data">
                  {{ formatDate(data.item.symptoms_date) }}
                </template>
                <template #cell(status)="row">
                  <p>{{ statusCheck(row) }}</p>
                </template>
                <template #cell(actions)="row">
                  <!-- @click="onDeleteQuestionnaireAnswer(row)" -->
                  <b-button
                    v-b-modal.modal-delete-answer-confirmation
                    variant="danger"
                    size="sm"
                    class="mr-2"
                    @click="setRowToDelete(row)"
                    >Delete</b-button
                  >
                </template>
              </b-table>
              <b-form-group>
                <b-button v-b-modal.modal-create-visit-record variant="primary"
                  >Create New</b-button
                >
                <!-- <b-button
                  v-b-modal.modal-auto-generate-questionaire
                  variant="success"
                  >Auto Generate</b-button
                > -->
              </b-form-group>
            </b-card-text>
          </b-tab>
          <!-- Meta Configuration -->
          <b-tab title="Additional Information">
            <b-card-text>
              <b-form>
                <!-- <b-form-group 
                  label="Gen Password:"
                  description="Password.">
                  <b-form-input type="text" v-model="patient.meta_data.gen_password" disabled></b-form-input>
                </b-form-group> -->

                <!-- <b-form-group
                  label="Default Record Length (Days):"
                  description="Default setting for number of days to record symptoms. "
                >
                  <b-form-input
                    type="number"
                    v-model="patient.meta_data.default_tracking_length_days"
                    disabled
                  ></b-form-input>
                </b-form-group> -->

                <!-- <b-form-group
                  label="Start Date:"
                  description="Date when patient should start recording symptoms."
                >
                  <b-form-datepicker
                    v-model="patient.meta_data.start_tracking_datetime"
                  ></b-form-datepicker>
                </b-form-group>

                <b-form-group
                  label="End Date:"
                  description="Date when patient should end recording symptoms."
                >
                  <b-form-datepicker
                    v-model="patient.meta_data.end_tracking_datetime"
                    disabled
                  ></b-form-datepicker>
                </b-form-group> -->
                <b-form-group
                  :label="uk_working_party_criteria_met_all_text"
                  :class="
                    uk_working_party_criteria_met_all == false ? 'red-text' : ''
                  "
                  description="Must have the 'Itchy skin condition in last 12 months' and 3 or more of the other conditions."
                >
                </b-form-group>
                <div class="pl-4">
                  <b-form-group
                    label="1a. Itchy skin condition in last 12 months"
                    v-slot="{ ariaDescribedby }"
                    :class="
                      patient.meta_data.uk_working_party_criteria_met_0 == null
                        ? 'red-text'
                        : ''
                    "
                  >
                    <b-form-radio-group
                      id="radio-uk-working-party-group-0"
                      required
                      v-model="
                        patient.meta_data.uk_working_party_criteria_met_0
                      "
                      :options="yes_no_options"
                      :aria-describedby="ariaDescribedby"
                      @change="check_uk_working_party_criteria_met_all"
                    ></b-form-radio-group>
                  </b-form-group>

                  <!-- Hide form is patient_age_at_registration is less than 2 -->

                  <b-form-group
                    v-if="patient_age_at_registration >= 4"
                    label="1b. Onset age <2 years"
                    v-slot="{ ariaDescribedby }"
                    :class="
                      patient.meta_data.uk_working_party_criteria_met_1 == null
                        ? 'red-text'
                        : ''
                    "
                  >
                    <b-form-radio-group
                      id="radio-uk-working-party-group-1"
                      v-model="
                        patient.meta_data.uk_working_party_criteria_met_1
                      "
                      :options="yes_no_options"
                      :aria-describedby="ariaDescribedby"
                      @change="check_uk_working_party_criteria_met_all"
                    ></b-form-radio-group>
                  </b-form-group>

                  <b-form-group
                    :label="uk_working_party_criteria_met_2_text"
                    v-slot="{ ariaDescribedby }"
                    :class="
                      patient.meta_data.uk_working_party_criteria_met_2 == null
                        ? 'red-text'
                        : ''
                    "
                  >
                    <b-form-radio-group
                      id="radio-uk-working-party-group-2"
                      v-model="
                        patient.meta_data.uk_working_party_criteria_met_2
                      "
                      :options="yes_no_options"
                      :aria-describedby="ariaDescribedby"
                      @change="check_uk_working_party_criteria_met_all"
                    ></b-form-radio-group>
                  </b-form-group>
                  <b-form-group
                    :label="uk_working_party_criteria_met_3_text"
                    v-slot="{ ariaDescribedby }"
                    :class="
                      patient.meta_data.uk_working_party_criteria_met_3 == null
                        ? 'red-text'
                        : ''
                    "
                  >
                    <b-form-radio-group
                      id="radio-uk-working-party-group-3"
                      v-model="
                        patient.meta_data.uk_working_party_criteria_met_3
                      "
                      :options="yes_no_options"
                      :aria-describedby="ariaDescribedby"
                      @change="check_uk_working_party_criteria_met_all"
                    ></b-form-radio-group>
                  </b-form-group>
                  <b-form-group
                    :label="uk_working_party_criteria_met_4_text"
                    v-slot="{ ariaDescribedby }"
                    :class="
                      patient.meta_data.uk_working_party_criteria_met_4 == null
                        ? 'red-text'
                        : ''
                    "
                  >
                    <b-form-radio-group
                      id="radio-uk-working-party-group-4"
                      v-model="
                        patient.meta_data.uk_working_party_criteria_met_4
                      "
                      :options="yes_no_options"
                      :aria-describedby="ariaDescribedby"
                      @change="check_uk_working_party_criteria_met_all"
                    ></b-form-radio-group>
                  </b-form-group>
                  <b-form-group
                    :label="uk_working_party_criteria_met_5_text"
                    v-slot="{ ariaDescribedby }"
                    :class="
                      patient.meta_data.uk_working_party_criteria_met_5 == null
                        ? 'red-text'
                        : ''
                    "
                    description="Judged using visible flexural eczema protocol), i.e. around eyes, side/front of neck, fronts of elbows, behind knees, front of ankles. Age <4 years: Additionally includes eczema on cheeks and outer surfaces of arms and legs."
                  >
                    <b-form-radio-group
                      id="radio-uk-working-party-group-5"
                      v-model="
                        patient.meta_data.uk_working_party_criteria_met_5
                      "
                      :options="yes_no_options"
                      :aria-describedby="ariaDescribedby"
                      @change="check_uk_working_party_criteria_met_all"
                    ></b-form-radio-group>
                  </b-form-group>
                </div>

                <b-form-group
                  :label="inclusion_criteria_met_all_text"
                  :class="inclusion_criteria_met_all == false ? 'red-text' : ''"
                  description="Questions 2a to 2e should be Yes, and 2f should be No."
                >
                </b-form-group>
                <div class="pl-4">
                  <p><em>Inclusion Criteria</em></p>
                  <b-form-group
                    label="2a. Children and young people age under 18 years with diverse skin tones"
                    v-slot="{ ariaDescribedby }"
                    :class="
                      patient.meta_data.inclusion_criteria_0 == null
                        ? 'red-text'
                        : ''
                    "
                  >
                    <b-form-radio-group
                      id="radio-inclusion-criteria-group-0"
                      v-model="patient.meta_data.inclusion_criteria_0"
                      :options="yes_no_options"
                      :aria-describedby="ariaDescribedby"
                      @change="check_inclusion_criteria_met_all"
                    ></b-form-radio-group>
                  </b-form-group>
                  <b-form-group
                    label="2b. Confirmed eczema diagnosis based on UK Working Party Diagnostic Criteria"
                    v-slot="{ ariaDescribedby }"
                    :class="
                      patient.meta_data.inclusion_criteria_1 == null
                        ? 'red-text'
                        : ''
                    "
                  >
                    <b-form-radio-group
                      id="radio-inclusion-criteria-group-1"
                      v-model="patient.meta_data.inclusion_criteria_1"
                      :options="yes_no_options"
                      :aria-describedby="ariaDescribedby"
                      @change="check_inclusion_criteria_met_all"
                    ></b-form-radio-group>
                  </b-form-group>
                  <b-form-group
                    label="2c. Visible eczema at the time of recruitment"
                    v-slot="{ ariaDescribedby }"
                    :class="
                      patient.meta_data.inclusion_criteria_2 == null
                        ? 'red-text'
                        : ''
                    "
                  >
                    <b-form-radio-group
                      id="radio-inclusion-criteria-group-2"
                      v-model="patient.meta_data.inclusion_criteria_2"
                      :options="yes_no_options"
                      :aria-describedby="ariaDescribedby"
                      @change="check_inclusion_criteria_met_all"
                    ></b-form-radio-group>
                  </b-form-group>
                  <b-form-group
                    label="2d. Written, informed consent of parent/legal guardian and patient consent/assent"
                    v-slot="{ ariaDescribedby }"
                    :class="
                      patient.meta_data.inclusion_criteria_3 == null
                        ? 'red-text'
                        : ''
                    "
                  >
                    <b-form-radio-group
                      id="radio-inclusion-criteria-group-3"
                      v-model="patient.meta_data.inclusion_criteria_3"
                      :options="yes_no_options"
                      :aria-describedby="ariaDescribedby"
                      @change="check_inclusion_criteria_met_all"
                    ></b-form-radio-group>
                  </b-form-group>

                  <p><em>Exclusion Criteria</em></p>
                  <b-form-group
                    label="2e. Eczema diagnosis not confirmed"
                    v-slot="{ ariaDescribedby }"
                    :class="
                      patient.meta_data.exclusion_criteria_0 == null
                        ? 'red-text'
                        : ''
                    "
                  >
                    <b-form-radio-group
                      id="radio-exclusion-criteria-group-0"
                      v-model="patient.meta_data.exclusion_criteria_0"
                      :options="yes_no_options"
                      :aria-describedby="ariaDescribedby"
                      @change="check_inclusion_criteria_met_all"
                    ></b-form-radio-group>
                  </b-form-group>
                  <b-form-group
                    label="2f. Severe underlying medical conditions that may present with eczematous lesions "
                    v-slot="{ ariaDescribedby }"
                    description="Not common in allergy clinics."
                    :class="
                      patient.meta_data.exclusion_criteria_1 == null
                        ? 'red-text'
                        : ''
                    "
                  >
                    <b-form-radio-group
                      id="radio-exclusion-criteria-group-1"
                      v-model="patient.meta_data.exclusion_criteria_1"
                      :options="yes_no_options"
                      :aria-describedby="ariaDescribedby"
                      @change="check_inclusion_criteria_met_all"
                    ></b-form-radio-group>
                  </b-form-group>
                </div>

                <!-- <b-form-group
                  label="Edit Window (Hours)"
                  description="Questionnaires must be submitted/edited in X hours from date of syndrome."
                >
                  <b-form-input
                    type="number"
                    v-model="patient.meta_data.submit_edit_window_hours"
                  ></b-form-input>
                </b-form-group> -->

                <!-- <b-form-group
                  label="Login Reminder Method"
                  description="Method that should be used to notify patient if inactive (no login) for X hours."
                >
                  <b-form-select
                    v-model="patient.meta_data.login_reminder_method"
                    :options="send_info_options"
                  ></b-form-select>
                </b-form-group> -->

                <b-form-group
                  label="3. Ethnicity"
                  description="as per ONS census."
                >
                  <b-form-select
                    v-model="patient.meta_data.ethnicity"
                    :options="ethnicity_options"
                    required
                  ></b-form-select>
                  <b-form-input
                    v-if="patient.meta_data.ethnicity == 'Other'"
                    type="text"
                    v-model="patient.meta_data.otherEthnicityValue"
                    placeholder="If Other, please specify."
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="4. Skin tone">
                  <b-form-select
                    v-model="patient.meta_data.skintone"
                    :options="skintone_options"
                    required
                  ></b-form-select>
                </b-form-group>

                <b-form-group label="5. Fitzpatrick Scale">
                  <b-form-select
                    v-model="patient.meta_data.FitzpatrickScale"
                    required
                    :options="Fitzpatrick_Scale_options"
                  ></b-form-select>
                </b-form-group>
                <p>
                  <b>NOTE</b>: refer to the table below for the corresponding
                  Fitzpatrick Scale.
                </p>
                <b-table bordered :items="table_Fitzpatrick_fields"></b-table>

                <b-form-group
                  label="6. Allergic co-morbidities"
                  v-slot="{ ariaDescribedby }"
                  :class="
                    patient.meta_data.allegic_co_morbidities_all == null
                      ? 'red-text'
                      : ''
                  "
                  description="If yes, record which ones."
                >
                  <b-form-radio-group
                    id="radio-allegic-co-morbidities-group-all"
                    v-model="patient.meta_data.allegic_co_morbidities_all"
                    :options="yes_no_options"
                    :aria-describedby="ariaDescribedby"
                  ></b-form-radio-group>
                </b-form-group>
                <div class="pl-4">
                  <b-form-group
                    label="6a. Allergic rhinitis"
                    v-if="patient.meta_data.allegic_co_morbidities_all == 'Yes'"
                    v-slot="{ ariaDescribedby }"
                    :class="
                      patient.meta_data.allegic_co_morbidities_0 == null
                        ? 'red-text'
                        : ''
                    "
                  >
                    <b-form-radio-group
                      id="radio-allegic-co-morbidities-group-0"
                      v-model="patient.meta_data.allegic_co_morbidities_0"
                      :options="yes_no_options"
                      :aria-describedby="ariaDescribedby"
                    ></b-form-radio-group>
                  </b-form-group>
                  <b-form-group
                    label="6b. Allergic conjunctivitis"
                    v-if="patient.meta_data.allegic_co_morbidities_all == 'Yes'"
                    v-slot="{ ariaDescribedby }"
                    :class="
                      patient.meta_data.allegic_co_morbidities_1 == null
                        ? 'red-text'
                        : ''
                    "
                  >
                    <b-form-radio-group
                      id="radio-allegic-co-morbidities-group-1"
                      v-model="patient.meta_data.allegic_co_morbidities_1"
                      :options="yes_no_options"
                      :aria-describedby="ariaDescribedby"
                    ></b-form-radio-group>
                  </b-form-group>
                  <b-form-group
                    label="6c. Environmental allergies"
                    v-if="patient.meta_data.allegic_co_morbidities_all == 'Yes'"
                    v-slot="{ ariaDescribedby }"
                    :class="
                      patient.meta_data.allegic_co_morbidities_2 == null
                        ? 'red-text'
                        : ''
                    "
                  >
                    <b-form-radio-group
                      id="radio-allegic-co-morbidities-group-2"
                      v-model="patient.meta_data.allegic_co_morbidities_2"
                      :options="yes_no_options"
                      :aria-describedby="ariaDescribedby"
                      description="If yes"
                    ></b-form-radio-group>
                  </b-form-group>
                  <b-form-group
                    v-if="
                      patient.meta_data.allegic_co_morbidities_all == 'Yes' &&
                      patient.meta_data.allegic_co_morbidities_2 == 'Yes'
                    "
                    label="If Yes to Environmental Allergies, please specify:"
                    v-slot="{ ariaDescribedby }"
                    :class="
                      patient.meta_data.allegic_co_morbidities_environmental ==
                      null
                        ? 'red-text'
                        : ''
                    "
                  >
                    <b-form-radio-group
                      id="radio-environmental-allegies-co-morbidities-group"
                      v-model="
                        patient.meta_data.allegic_co_morbidities_environmental
                      "
                      :options="environmental_allegies_options"
                      :aria-describedby="ariaDescribedby"
                    ></b-form-radio-group>
                  </b-form-group>
                  <b-form-group
                    label="6d. Asthma"
                    v-if="patient.meta_data.allegic_co_morbidities_all == 'Yes'"
                    v-slot="{ ariaDescribedby }"
                    :class="
                      patient.meta_data.allegic_co_morbidities_3 == null
                        ? 'red-text'
                        : ''
                    "
                  >
                    <b-form-radio-group
                      id="radio-allegic-co-morbidities-group-3"
                      v-model="patient.meta_data.allegic_co_morbidities_3"
                      :options="yes_no_options"
                      :aria-describedby="ariaDescribedby"
                    ></b-form-radio-group>
                  </b-form-group>
                  <b-form-group
                    label="6e. Food Allergies"
                    v-if="patient.meta_data.allegic_co_morbidities_all == 'Yes'"
                    v-slot="{ ariaDescribedby }"
                    :class="
                      patient.meta_data.allegic_co_morbidities_4 == null
                        ? 'red-text'
                        : ''
                    "
                  >
                    <b-form-radio-group
                      id="radio-allegic-co-morbidities-group-4"
                      v-model="patient.meta_data.allegic_co_morbidities_4"
                      :options="yes_no_options"
                      :aria-describedby="ariaDescribedby"
                    ></b-form-radio-group>
                  </b-form-group>
                </div>

                <!-- <b-form-group
                  label="Login Reminder (Hours)"
                  description="Patient will be reminded to login if inactivity (no login) for X hours."
                >
                  <b-form-input
                    type="number"
                    v-model="patient.meta_data.login_remind_patient_hours"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label="Login Reminder - Clinician(Hours)"
                  description="Clinician will be notified if patient is inactive (no login) for X hours."
                >
                  <b-form-input
                    type="number"
                    v-model="patient.meta_data.login_remind_clinician_hours"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label="Last Login Reminder (Patient):"
                  description="Last time patient being reminded to login."
                >
                  <b-form-input
                    type="text"
                    v-model="patient.meta_data.last_login_remind_patient_datetime"
                    disabled
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Last Login Reminder (Clinician):"
                  description="Last time clinician being reminded for inactivity."
                >
                  <b-form-input
                    type="text"
                    v-model="patient.meta_data.last_login_remind_clinician_datetime"
                    disabled
                  ></b-form-input>
                </b-form-group> -->

                <b-form-group
                  label="Clinician Notes:"
                  description="Notes and scribbles."
                >
                  <b-form-textarea
                    v-model="patient.meta_data.clinician_notes"
                    placeholder="Clinician Notes ..."
                    rows="3"
                    max-rows="12"
                    no-auto-shrink
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group>
                  <b-button
                    @click="onUpdatePatient('additional')"
                    variant="primary"
                    >Save Additional Information</b-button
                  >
                </b-form-group>

                <!-- <p>{{patient.meta_data}}</p> -->
              </b-form>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
    <!-- begin::Modal -->
    <b-modal id="modal-view-questionnaire" title="Edit/View Visit Data">
      The visit data will appear on another window.
      <template #modal-footer="{}">
        <b-button size="sm" @click="onCloseViewQuestionnaire"> Close </b-button>
      </template>
    </b-modal>
    <!-- end::Modal -->
    <!-- begin::Modal -->
    <b-modal id="modal-delete-patient-confirmation" title="Delete Patient">
      <h4>Are you sure you want to delete this patient?</h4>
      <template #modal-footer="{}">
        <b-button size="sm" @click="onCancelDeletePatient"> Cancel </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="danger" @click="onConfirmDeletePatient">
          Confirm
        </b-button>
      </template>
    </b-modal>
    <!-- end::Modal -->
    <!-- begin::Modal -->
    <b-modal id="modal-delete-entity-confirmation" title="Delete Entity">
      <h4>Are you sure you want to delete this questionnaire Group?</h4>
      <p>This will delete all the data that belongs to this group</p>
      <template #modal-footer="{}">
        <b-button size="sm" @click="onCancelDeleteEntity"> Cancel </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="danger" @click="onConfirmDeleteEntity">
          Confirm
        </b-button>
      </template>
    </b-modal>
    <!-- end::Modal -->
    <!-- begin::Modal -->
    <b-modal id="modal-delete-answer-confirmation" title="Delete Visit Record">
      <h4>Are you sure you want to delete this visit record?</h4>
      <template #modal-footer="{}">
        <b-button size="sm" @click="onCancelDeleteAnswer"> Cancel </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="danger" @click="onConfirmDeleteAnswer">
          Confirm
        </b-button>
      </template>
    </b-modal>
    <!-- end::Modal -->
    <!-- begin::Modal -->
    <b-modal
      id="modal-auto-generate-questionaire"
      title="Auto generate questionnaires"
    >
      <h4>
        Are you sure you want to auto generate questionnaires based on study
        configuration?
      </h4>
      <template #modal-footer="{}">
        <b-button size="sm" @click="onCancelAutoGenerateQuestionaires">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button
          size="sm"
          variant="success"
          @click="onAutoGenerateQuestionaires"
        >
          Confirm
        </b-button>
      </template>
    </b-modal>
    <!-- end::Modal -->
    <!-- begin::Modal -->
    <!-- <b-modal id="modal-create-group" title="Create Questionnaire Group">
      <b-form>
        <b-form-group label="Questionnaire Types">
          <b-form-checkbox-group
            id="new-submission-typepicker"
            v-model="new_group.ids"
            :options="questionaire_types"
            inline
          ></b-form-checkbox-group>
        </b-form-group>
        <b-form-group label="Study Group">
          <b-form-select
            v-model="new_group.groupId"
            :options="patient_groups_id"
          ></b-form-select>
        </b-form-group>
        <b-form-group label="Description">
          <b-form-input
            v-model="new_group.description"
            placeholder="Enter description"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ cancel, submit }">
        <b-button size="sm" @click="onCancelCreateGroup"> Cancel </b-button>
        <b-button size="sm" variant="danger" @click="onCreateGroup">
          Confirm
        </b-button>
      </template>
    </b-modal> -->
    <!-- end::Modal -->
    <!-- begin::Modal -->
    <b-modal id="modal-create-visit-record" title="Create Visit Record">
      <b-form>
        <!-- <b-form-group label="Questionnaire Type">
          <b-form-select
            id="new-submission-typepicker"
            v-model="new_questionaire.id"
            :options="questionaire_types"
          ></b-form-select>
        </b-form-group> -->
        <!-- TODO unable to enter value -->
        <b-form-group label="Clinician/researcher name">
          <b-form-input
            v-model="new_questionaire.clinician_name"
            placeholder="Enter name of clinician/reseracher"
            type="text"
          ></b-form-input>
        </b-form-group>
        <!-- TODO save the visit number -->
        <!-- <b-form-group label="Visit number">
          <b-form-input
            v-model="new_questionaire.visitNum"
            placeholder="Enter visit number"
            type="number"
          ></b-form-input>
        </b-form-group> -->
        <b-form-group label="Date of Visit">
          <!-- <b-form-input
            id="new-submission-datepicker"
            v-model="new_questionaire.date"
            type="date"
            required
          ></b-form-input> -->
          <Datepicker
            v-model="new_questionaire.date"
            id="new-submission-datepicker"
            placeholder="Select Date"
            typeable
            required
            clear-button
            :bootstrap-styling="true"
            wrapper-class="fullscreen-when-on-mobile"
          ></Datepicker>
        </b-form-group>
      </b-form>
      <template #modal-footer="{}">
        <b-button size="sm" @click="onCancelCreateQuestionaire">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="danger" @click="onCreateQuestionaire">
          Confirm
        </b-button>
      </template>
    </b-modal>
    <!-- end::Modal -->
    <!-- begin::Modal -->
    <b-modal id="modal-send-access-info" title="Send Access Information">
      <b-form>
        <b-form-group label="Send Method">
          <b-form-select
            id="new-send-access-info-method"
            v-model="send_access_info.method"
            :options="send_info_options"
          ></b-form-select>
        </b-form-group>
        <!-- <b-form-group label="Send on which day?">
          <b-form-datepicker id="new-send-access-info-date" v-model="send_access_info.date" today-button locale="en"></b-form-datepicker>
        </b-form-group>
        <b-form-group label="Send at what time?">
          <b-form-timepicker id="new-send-access-info-time" v-model="send_access_info.time" now-button locale="en"></b-form-timepicker>
        </b-form-group> -->
      </b-form>
      <template #modal-footer="{}">
        <b-button size="sm" @click="onCancelSendAccessInfo"> Cancel </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="success" @click="onSendAccessInfo">
          Send
        </b-button>
      </template>
    </b-modal>
    <!-- end::Modal -->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import moment from "moment";
import Datepicker from "vuejs-datepicker"; // https://yarnpkg.com/package?name=vuejs-datepicker
// import { v4 as uuidv4 } from 'uuid'
// import api from '@/services/api';

export default {
  name: "patient",
  components: {
    Datepicker,
  },
  data() {
    return {
      show: true,
      // TODO: implement sorting of other fields when we press on the table header
      q_sortBy: "symptoms_date",
      q_sortDesc: true,
      // ----- Patient section -----
      gender_options: [
        { text: "Male", value: "Male" },
        { text: "Female", value: "Female" },
        { text: "Other", value: "Other" },
      ],
      send_info_options: [
        { text: "Via Email & Phone", value: "Both" },
        { text: "Via Email only", value: "Email" },
        { text: "Via Phone only", value: "Phone" },
      ],
      yes_no_options: [
        { text: "Yes", value: "Yes" },
        { text: "No", value: "No" },
      ],
      ethnicity_options: [
        {
          text: "Black/African/Caribbean/ Black British:	African",
          value: "African",
        },
        {
          text: "Black/African/Caribbean/ Black British:	Caribbean",
          value: "Caribbean",
        },
        {
          text: "Black/African/Caribbean/ Black British:	Other Black",
          value: "Other_Black",
        },
        { text: "Asian/Asian British:	Indian", value: "Indian" },
        { text: "Asian/Asian British:	Pakistani", value: "Pakistani" },
        { text: "Asian/Asian British:	Bangladeshi", value: "Bangladeshi" },
        { text: "Asian/Asian British:	Chinese", value: "Chinese" },
        { text: "Asian/Asian British:	Other Asian", value: "Other_Asian" },
        {
          text: "Mixed/Multiple ethnic group:	White and Black Caribbean",
          value: "White_and_Black_Caribbean",
        },
        {
          text: "Mixed/Multiple ethnic group:	White and Black African",
          value: "White_and_Black_African",
        },
        {
          text: "Mixed/Multiple ethnic group:	White and Asian",
          value: "White_and_Asian",
        },
        {
          text: "Mixed/Multiple ethnic group:	Other mixed",
          value: "Other_mixed",
        },
        { text: "Other ethnic Group:	Arab", value: "Arab" },
        {
          text: "Other ethnic Group:	Any other ethnic group",
          value: "Any_other_ethnic_group",
        },
        { text: "White:	British", value: "British" },
        {
          text: "White:	Gypsy or Irish Traveller",
          value: "Gypsy_or_Irish_Traveller",
        },
        { text: "White:	Other white", value: "Other_white" },
        { text: "Do not wish to declare", value: "Do_not_wish_to_declare" },
        { text: "Other", value: "Other" },
        { text: "Missing", value: "Missing" },
      ],
      skintone_options: [
        { text: "Fair", value: "Fair" },
        { text: "Light", value: "Light" },
        { text: "Medium", value: "Medium" },
        { text: "Dark", value: "Dark" },
        { text: "Missing", value: "Missing" },
      ],
      table_Fitzpatrick_fields: [
        {
          Skin_Type: "I",
          typical_features: "Fair white skin, blue/green eyes, blond/red hair",
          tanning_ability: "Always burns, does not tan",
        },
        {
          Skin_Type: "II",
          typical_features: "White skin",
          tanning_ability: "Burns easily, tans poorly",
        },
        {
          Skin_Type: "III",
          typical_features: "Darker white skin",
          tanning_ability: "Tans after initial burn",
        },
        {
          Skin_Type: "IV",
          typical_features: "Light brown skin",
          tanning_ability: "Burns minimally, tans easily",
        },
        {
          Skin_Type: "V",
          typical_features: "Brown skin",
          tanning_ability: "Rarely burns, tans easily",
        },
        {
          Skin_Type: "VI",
          typical_features: "Dark brown",
          tanning_ability: "Rarely burns, tans easily",
        },
      ],
      Fitzpatrick_Scale_options: [
        { text: "I", value: "I" },
        { text: "II", value: "II" },
        { text: "III", value: "III" },
        { text: "IV", value: "IV" },
        { text: "V", value: "V" },
        { text: "VI", value: "VI" },
        { text: "Missing", value: "Missing" },
      ],
      environmental_allegies_options: [
        { text: "Perennial", value: "Perennial" },
        { text: "Seasonal", value: "Seasonal" },
        { text: "Both Perennial and Seasonal", value: "Both" },
      ],
      send_access_info: {
        method: "Both",
        date: null,
        time: null,
      },
      patient: {
        id: null,
        // username_email: null,
        // firstname: null,
        // lastname: null,
        gender: null,
        birthday: null,
        clinical_reference: null,
        research_consent: null,
        privacy_consent: null,
        cookie_consent: null,
        meta_data: {
          gender_other_text: null,
          single_visit_consent: null,
          multiple_visit_consent: null,
          uk_working_party_criteria_met_0: null,
          uk_working_party_criteria_met_1: null,
          uk_working_party_criteria_met_2: null,
          uk_working_party_criteria_met_3: null,
          uk_working_party_criteria_met_4: null,
          uk_working_party_criteria_met_5: null,
          inclusion_criteria_0: null,
          inclusion_criteria_1: null,
          inclusion_criteria_2: null,
          inclusion_criteria_3: null,
          exclusion_criteria_0: null,
          exclusion_criteria_1: null,
          ethnicity: null,
          otherEthnicityValue: null,
          skintone: null,
          FitzpatrickScale: null,
          allegic_co_morbidities_all: null,
          allegic_co_morbidities_0: null,
          allegic_co_morbidities_1: null,
          allegic_co_morbidities_2: null,
          allegic_co_morbidities_3: null,
          allegic_co_morbidities_4: null,
          allegic_co_morbidities_environmental: null,
          clinician_notes: "",
        },
        groups: [],
      },
      patient_age_at_registration: null,
      uk_working_party_criteria_met_all_text:
        "1. UK Working Party Criteria Met: No",
      uk_working_party_criteria_met_2_text:
        "1c. History of flexural involvement",
      uk_working_party_criteria_met_3_text: "1d. History of generally dry skin",
      uk_working_party_criteria_met_4_text:
        "1e. Personal history of other atopic disease",
      uk_working_party_criteria_met_5_text: "1f. Visible flexural dermatitis",
      uk_working_party_criteria_met_all: false,
      inclusion_criteria_met_all_text: "2. Inclusion Criteria Met: No",
      inclusion_criteria_met_all: false,
      patient_group: { name: "N/A" },
      // ----- Questionnaire section -----
      activeTab: 0,
      selectedEntityId: null,
      patient_answers: null,
      patient_entities: null,
      table_entities_fields: [
        // { key: "groupId", label: "Group Id" },
        { key: "id", label: "Id" },
        { key: "updatedAt", label: "Date Last Modified" },
        { key: "description", label: "Description" },
        { key: "actions", label: "Delete Record" },
      ],
      table_questionnaires_fields: [
        // { key: "id", label: "Id" },
        // { key: "updatedAt", label: "Date Filled in" }, // TODO: check if we should use symptoms date or smth else?
        // { key: "symptoms_date", label: "Effective Date" }, // replaced Effective Date with Visit Date. Do we change symptoms_date?
        { key: "symptoms_date", label: "Visit Date" },
        // { key: "questionnaire_type", label: "Type" },
        { key: "status", label: "Status" },
        { key: "actions", label: "Delete Record" },
      ],
      rowToDelete: null,
      new_password: null,
      new_group: {
        description: "",
        groupId: null,
        // patientId: null,
        questionnaireIds: [],
      },
      new_questionaire: {
        id: null,
        clinician_name: "",
        // visitNum: null,
        entityId: null,
        date: null,
      },
      questionaire_types: [
        { value: -1, text: "No available questionnaires found" },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    patient_groups() {
      return this.patient.groups.map((group) => ({
        text: group.name,
        value: group,
      }));
    },
    patient_groups_id() {
      return this.patient.groups.map((group) => ({
        text: group.name,
        value: group.id,
      }));
    },
    filteredPatientAnswers() {
      if (this.selectedEntityId === null) {
        return this.patient_answers; // Return all if no entity is selected
      }
      return this.patient_answers.filter(
        (answer) => answer.entityGroupId === this.selectedEntityId
      );
    },
    dateToString(date) {
      return date.toString().substr(0, 10);
    },
    // dob: {
    //   get() {
    //     return this.patient.birthday
    //       ? moment(new Date(this.patient.birthday)).format("YYYY-MM-DD")
    //       : null;
    //   },
    //   set(newValue) {
    //     this.patient.birthday = moment(new Date(newValue)).format("YYYY-MM-DD");
    //   },
    // },
    // last_updated: {
    //   get() {
    //     return this.patient.updatedAt
    //       ? moment(new Date(this.patient.updatedAt)).format("YYYY-MM-DD")
    //       : "";
    //   },
    //   set(newValue) {
    //     this.patient.updatedAt = moment(new Date(newValue)).format(
    //       "YYYY-MM-DD"
    //     );
    //   },
    // },
  },
  watch: {
    $route() {
      this.refresh();
    },
    selectedEntityId(newVal) {
      this.new_questionaire.entityId = newVal;
    },
    patient_group(newVal) {
      this.new_group.groupId = newVal.id;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Patients" }]);
    this.refresh();
  },
  methods: {
    async refresh() {
      await ApiService.get("/users/patient/" + this.$route.params.id).then(
        ({ data }) => {
          this.patient = data.data;
          // TODO: handle default group from the route. (if coming from group page use that, if coming from patient page then unselected)
          // TODO: filter questionnaire data by group selected
          this.patient_group = this.patient.groups[0];

          // recompute the age at registration
          const this_dob = new Date(data.data.birthday);
          const createdDate = new Date(data.data.createdAt);
          this.patient_age_at_registration = Math.floor(
            (createdDate - this_dob) / (1000 * 60 * 60 * 24 * 365.25)
          );

          // Unable to use this.patient_age_at_registration in the if statement
          // we always get null/undefined value even when setting await for the api call
          if (
            Math.floor(
              (createdDate - this_dob) / (1000 * 60 * 60 * 24 * 365.25)
            ) >= 4
          ) {
            this.uk_working_party_criteria_met_2_text =
              "1c. History of flexural involvement";
            this.uk_working_party_criteria_met_3_text =
              "1d. History of generally dry skin";
            this.uk_working_party_criteria_met_4_text =
              "1e. Personal history of other atopic disease";
            this.uk_working_party_criteria_met_5_text =
              "1f. Visible flexural dermatitis";
          } else {
            this.uk_working_party_criteria_met_2_text =
              "1b. History of flexural involvement";
            this.uk_working_party_criteria_met_3_text =
              "1c. History of generally dry skin";
            this.uk_working_party_criteria_met_4_text =
              "1d. Personal or 1st degree relative history of other atopic disease";
            this.uk_working_party_criteria_met_5_text =
              "1e. Visible flexural dermatitis";
          }
        }
      );

      this.check_uk_working_party_criteria_met_all();
      this.check_inclusion_criteria_met_all();

      ApiService.get(
        "/questionnaires/answers/patient/" + this.$route.params.id
      ).then(({ data }) => {
        this.patient_answers = data.data;
      });

      ApiService.get("/entities/users/" + this.$route.params.id).then(
        ({ data }) => {
          this.patient_entities = data.data;
        }
      );

      ApiService.get("/questionnaires/types/").then(({ data }) => {
        this.questionaire_types = data.data.map((questionnaire) => ({
          value: questionnaire.id,
          text: questionnaire.name,
        }));
      });
    },
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    statusCheck(row) {
      if (row.item.answer_json == "in_progress") {
        return "In Progress";
      } else if (row.item.answer_json == "completed") {
        return "Completed";
      } else {
        // example null
        return "Empty";
      }
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      const year = date.getFullYear();
      // const month = String(date.getMonth() + 1).padStart(2, "0"); // +1 because months are 0-indexed
      const month = date.toLocaleString("default", { month: "short" });
      const day = String(date.getDate()).padStart(2, "0");
      return `${day} ${month} ${year}`;
    },
    getQuestionnaireTypeText(questionnaireId) {
      const matchedType = this.questionaire_types.find(
        (i) => i.value === questionnaireId
      );
      return matchedType ? matchedType.text : "N/A";
    },
    onCancelDeletePatient(evt) {
      evt.preventDefault();
      this.$bvModal.hide("modal-delete-patient-confirmation");
    },
    onCancelDeleteEntity(evt) {
      evt.preventDefault();
      this.$bvModal.hide("modal-delete-entity-confirmation");
    },
    onCancelDeleteAnswer(evt) {
      evt.preventDefault();
      this.$bvModal.hide("modal-delete-answer-confirmation");
    },
    onCloseViewQuestionnaire(evt) {
      evt.preventDefault();
      this.$bvModal.hide("modal-view-questionnaire");
      this.refresh();
    },
    entityRowClickHandler(item) {
      this.activeTab = 0;
      this.selectedEntityId = item.id;
    },
    resetSelectedEntity() {
      this.selectedEntityId = null;
    },
    async questionnaireRowClickHandler(item) {
      this.$bvModal.show("modal-view-questionnaire");
      // show the upload image, severity scores, and questionnaires page
      window.open(
        "#/uploadimage/" + this.$route.params.id + "/" + item.id,
        "_blank"
      );
    },
    onConfirmDeletePatient() {
      ApiService.delete("/users/patient/" + this.$route.params.id)
        .then(() => {
          // Success
          this.$router.push("/");
        })
        .catch(() => {
          // Failure
          alert("Error: Delete patient failed.");
        });
    },
    setRowToDelete(row) {
      this.rowToDelete = row;
    },
    onConfirmDeleteAnswer() {
      const row = this.rowToDelete;
      this.onDeleteQuestionnaireAnswer(row);
    },
    onConfirmDeleteEntity() {
      const row = this.rowToDelete;
      ApiService.delete("/entities/" + row.item.id)
        .then(() => {
          this.$bvModal.hide("modal-delete-entity-confirmation");
          this.refresh();
        })
        .catch(() => {
          alert("Error: Failed to delete questionnaire group.");
        });
    },
    onCancelCreateGroup(evt) {
      evt.preventDefault();
      this.$bvModal.hide("modal-create-group");
    },
    onCancelCreateQuestionaire(evt) {
      evt.preventDefault();
      this.$bvModal.hide("modal-create-visit-record");
    },
    onCreateGroup() {
      ApiService.post(
        "/entities/users/" +
          this.$route.params.id +
          "/groups/" +
          this.new_group.groupId,
        {
          description: this.new_group.description,
          questionnaireIds: this.new_group.ids,
        }
      )
        .then(() => {
          this.$bvModal.hide("modal-create-group");
          this.refresh();
        })
        .catch(() => {
          alert("Error creating new questionnaire entry.");
        });
    },
    onCreateQuestionaire() {
      // check if the visit date is unique
      if (
        this.patient_answers.some(
          (answer) =>
            answer.symptoms_date.toString().substr(0, 10) ===
            this.new_questionaire.date
        )
      ) {
        alert("Visit date already exists");
        return;
      }

      if (this.new_questionaire.date == null) {
        alert("Please enter a visit date");
        return;
      }

      ApiService.post(
        "/questionnaires/answers/patient/" + this.$route.params.id,
        {
          questionnaireId: 1, // this.new_questionaire.id, TODO: fix this
          entityId: this.new_questionaire.entityId,
          symptomsDate: this.new_questionaire.date,
          clinician_name: this.new_questionaire.clinician_name,
        }
      )
        .then(() => {
          // Success
          this.$bvModal.hide("modal-create-visit-record");
          this.refresh();
        })
        .catch(() => {
          // Failure
          alert("Error creating a new visit record.");
        });
    },
    onDeleteQuestionnaireAnswer(row) {
      // if (row.item.answer_json == null) {
      ApiService.delete(
        "/questionnaires/answers/patient/" +
          this.$route.params.id +
          "/" +
          row.item.id
      )
        .then(() => {
          this.$bvModal.hide("modal-delete-answer-confirmation");
          // Success
          this.refresh();
        })
        .catch(() => {
          // Failure
          alert("Error: Failed to delete visit record.");
        });
      // }
    },
    onCancelAutoGenerateQuestionaires(evt) {
      evt.preventDefault();
      this.$bvModal.hide("modal-auto-generate-questionaire");
    },
    // TODO: test this
    onAutoGenerateQuestionaires() {
      ApiService.post(
        "/questionnaires/answers/autogen/patient" + this.$route.params.id
      )
        .then(() => {
          // Success
          this.$bvModal.hide("modal-auto-generate-questionaire");
          this.refresh();
        })
        .catch(() => {
          // Failure
          alert("Error auto generating new questionnaires.");
        });
    },
    onCancelSendAccessInfo(evt) {
      evt.preventDefault();
      this.$bvModal.hide("modal-send-access-info");
    },
    // onResendAccessInfo() {
    //   this.send_access_info = {
    //     method: "Both",
    //     date: null,
    //     time: null,
    //   };
    //   this.$bvModal.show("modal-send-access-info");
    // },
    onSendAccessInfo() {
      ApiService.post(
        "/users/patient/" + this.$route.params.id + "/send-access",
        {
          send_access_info_option: this.send_access_info.method,
        }
      )
        .then(() => {
          // Success
          alert("Success: Resent access information");
          this.$bvModal.hide("modal-send-access-info");
        })
        .catch(() => {
          // Failure
          alert("Error: Failed to resend access information");
        });
    },
    checkInvalidInputField(field) {
      if (field == null || field == "") {
        return true;
      }
      return false;
    },
    check_uk_working_party_criteria_met_all() {
      var uk_working_party_condition_null =
        this.patient.meta_data.uk_working_party_criteria_met_0 == null ||
        this.patient.meta_data.uk_working_party_criteria_met_2 == null ||
        this.patient.meta_data.uk_working_party_criteria_met_3 == null ||
        this.patient.meta_data.uk_working_party_criteria_met_4 == null ||
        this.patient.meta_data.uk_working_party_criteria_met_5 == null;

      if (this.patient_age_at_registration >= 4) {
        uk_working_party_condition_null =
          uk_working_party_condition_null ||
          this.patient.meta_data.uk_working_party_criteria_met_1 == null;
      }

      if (uk_working_party_condition_null) {
        this.uk_working_party_criteria_met_all = false;
        this.uk_working_party_criteria_met_all_text =
          "1. UK Working Party Criteria Met: No";
        return;
      }

      // Must have the 'Itchy skin condition in last 12 months' and 3 of the other conditions.
      if (this.patient.meta_data.uk_working_party_criteria_met_0 == "No") {
        this.uk_working_party_criteria_met_all = false;
        this.uk_working_party_criteria_met_all_text =
          "1. UK Working Party Criteria Met: No";
        return;
      }

      // user has answered all the uk working party questions
      // Must have the 'Itchy skin condition in last 12 months' and 3 of the other conditions.
      var count_uk_working_party_criteria_met = 0;
      if (this.patient_age_at_registration >= 4) {
        if (this.patient.meta_data.uk_working_party_criteria_met_1 == "Yes") {
          count_uk_working_party_criteria_met++;
        }
      }
      if (this.patient.meta_data.uk_working_party_criteria_met_2 == "Yes") {
        count_uk_working_party_criteria_met++;
      }
      if (this.patient.meta_data.uk_working_party_criteria_met_3 == "Yes") {
        count_uk_working_party_criteria_met++;
      }
      if (this.patient.meta_data.uk_working_party_criteria_met_4 == "Yes") {
        count_uk_working_party_criteria_met++;
      }
      if (this.patient.meta_data.uk_working_party_criteria_met_5 == "Yes") {
        count_uk_working_party_criteria_met++;
      }
      if (count_uk_working_party_criteria_met < 3) {
        this.uk_working_party_criteria_met_all = false;
        this.uk_working_party_criteria_met_all_text =
          "1. UK Working Party Criteria Met: No";
      } else {
        this.uk_working_party_criteria_met_all = true;
        this.uk_working_party_criteria_met_all_text =
          "1. UK Working Party Criteria Met: Yes";
      }
    },
    check_inclusion_criteria_met_all() {
      if (
        this.patient.meta_data.inclusion_criteria_0 == "No" ||
        this.patient.meta_data.inclusion_criteria_0 == null ||
        this.patient.meta_data.inclusion_criteria_1 == "No" ||
        this.patient.meta_data.inclusion_criteria_1 == null ||
        this.patient.meta_data.inclusion_criteria_2 == "No" ||
        this.patient.meta_data.inclusion_criteria_2 == null ||
        this.patient.meta_data.inclusion_criteria_3 == "No" ||
        this.patient.meta_data.inclusion_criteria_3 == null ||
        this.patient.meta_data.exclusion_criteria_0 == "Yes" ||
        this.patient.meta_data.exclusion_criteria_0 == null ||
        this.patient.meta_data.exclusion_criteria_1 == "Yes" ||
        this.patient.meta_data.exclusion_criteria_1 == null
      ) {
        this.inclusion_criteria_met_all = false;
        this.inclusion_criteria_met_all_text = "2. Inclusion criteria met: No";
      } else {
        this.inclusion_criteria_met_all = true;
        this.inclusion_criteria_met_all_text = "2. Inclusion criteria met: Yes";
      }
    },
    onUpdatePatient(panel) {
      // check if all information are provided
      var errors = [];
      if (panel == "main") {
        if (this.checkInvalidInputField(this.patient.id)) {
          errors.push("Id");
        }
        // if (this.checkInvalidInputField(this.patient.username_email)) {
        //   errors.push("Email");
        // }
        // if (this.checkInvalidInputField(this.patient.firstname)) {
        //   errors.push("First name");
        // }
        // if (this.checkInvalidInputField(this.patient.lastname)) {
        //   errors.push("Last name");
        // }
        if (this.checkInvalidInputField(this.patient.gender)) {
          errors.push("Gender");
        }
        if (this.patient.gender == "Other") {
          if (
            this.checkInvalidInputField(
              this.patient.meta_data.gender_other_text
            )
          ) {
            errors.push("Other gender description");
          }
        }
        if (this.checkInvalidInputField(this.patient.birthday)) {
          errors.push("Birthday");
        }
      } else if (panel == "additional") {
        // if (this.uk_working_party_criteria_met_all == null) {
        //   errors.push("UK Working Party Criteria Met");
        // }
        if (this.patient.meta_data.uk_working_party_criteria_met_0 == null) {
          errors.push("Itchy skin condition in last 12 months");
        }
        if (this.patient_age_at_registration >= 4) {
          if (this.patient.meta_data.uk_working_party_criteria_met_1 == null) {
            errors.push("Onset age <2 years");
          }
        }
        if (this.patient.meta_data.uk_working_party_criteria_met_2 == null) {
          errors.push("History of flexural involvement");
        }
        if (this.patient.meta_data.uk_working_party_criteria_met_3 == null) {
          errors.push("History of generally dry skin");
        }
        if (this.patient.meta_data.uk_working_party_criteria_met_4 == null) {
          errors.push("Personal history of other atopic disease");
        }
        if (this.patient.meta_data.uk_working_party_criteria_met_5 == null) {
          errors.push("Visible flexural dermatitis");
        }

        if (this.patient.meta_data.inclusion_criteria_0 == null) {
          errors.push(
            "Children and young people age under 18 years with diverse skin tones"
          );
        }
        if (this.patient.meta_data.inclusion_criteria_1 == null) {
          errors.push(
            "Confirmed eczema diagnosis based on UK Working Party Diagnostic Criteria"
          );
        }
        if (this.patient.meta_data.inclusion_criteria_2 == null) {
          errors.push("Visible eczema at the time of recruitment");
        }
        if (this.patient.meta_data.inclusion_criteria_3 == null) {
          errors.push(
            "Written, informed consent of parent/legal guardian and patient consent/assent"
          );
        }
        if (this.patient.meta_data.exclusion_criteria_0 == null) {
          errors.push("Eczema diagnosis is confirmed");
        }
        if (this.patient.meta_data.exclusion_criteria_1 == null) {
          errors.push(
            "Severe underlying medical conditions that may present with eczematous lesions"
          );
        }
        if (this.patient.meta_data.ethnicity == null) {
          errors.push("Ethnicity");
        }
        if (this.patient.meta_data.ethnicity == "Other") {
          if (this.patient.meta_data.otherEthnicityValue == null) {
            errors.push("Ethicity (Other) description");
          }
        }
        if (this.patient.meta_data.skintone == null) {
          errors.push("Skin tone");
        }
        if (this.patient.meta_data.FitzpatrickScale == null) {
          errors.push("Fitzpatrick Scale");
        }
        if (this.patient.meta_data.allegic_co_morbidities_all == null) {
          errors.push("Allergic co-morbidities");
        }
        if (this.patient.meta_data.allegic_co_morbidities_all == "Yes") {
          if (this.patient.meta_data.allegic_co_morbidities_0 == null) {
            errors.push("Allergic rhinitis");
          }
          if (this.patient.meta_data.allegic_co_morbidities_1 == null) {
            errors.push("Allergic conjunctivitis");
          }
          if (this.patient.meta_data.allegic_co_morbidities_2 == null) {
            errors.push("Environmental allergies");
          }
          if (this.patient.meta_data.allegic_co_morbidities_2 == "Yes") {
            if (
              this.patient.meta_data.allegic_co_morbidities_environmental ==
              null
            ) {
              errors.push("Environmental allergies, please specify");
            }
          }
          if (this.patient.meta_data.allegic_co_morbidities_3 == null) {
            errors.push("Asthma");
          }
          if (this.patient.meta_data.allegic_co_morbidities_4 == null) {
            errors.push("Food allergies");
          }
        }
      }

      // prompt user to fill in all the required fields
      if (errors.length > 0) {
        let alert_text = "Please fill in all the required fields: ";

        // write each error on a new line
        for (var i = 0; i < errors.length; i++) {
          alert_text += "\n \u2022" + errors[i];
        }

        alert(alert_text);
        return false;
      }

      if (panel == "main") {
        // in case there are changes to the birthday, the questions for uk working party need to be re-worded

        // recompute the age at registration
        const this_dob = new Date(this.patient.birthday);
        const createdDate = new Date(this.patient.createdAt);
        this.patient_age_at_registration = Math.floor(
          (createdDate - this_dob) / (1000 * 60 * 60 * 24 * 365.25)
        );

        if (
          Math.floor(
            (createdDate - this_dob) / (1000 * 60 * 60 * 24 * 365.25)
          ) >= 4
        ) {
          this.uk_working_party_criteria_met_2_text =
            "1c. History of flexural involvement";
          this.uk_working_party_criteria_met_3_text =
            "1d. History of generally dry skin";
          this.uk_working_party_criteria_met_4_text =
            "1e. Personal history of other atopic disease";
          this.uk_working_party_criteria_met_5_text =
            "1f. Visible flexural dermatitis";
        } else {
          this.uk_working_party_criteria_met_2_text =
            "1b. History of flexural involvement";
          this.uk_working_party_criteria_met_3_text =
            "1c. History of generally dry skin";
          this.uk_working_party_criteria_met_4_text =
            "1d. Personal or 1st degree relative history of other atopic disease";
          this.uk_working_party_criteria_met_5_text =
            "1e. Visible flexural dermatitis";
        }
      } else {
        // Additional alerts if
        // 1) the uk working party criteria are not met
        // 2) the inclusion/exclusion criteria are not met

        // check the uk working party criteria
        if (this.uk_working_party_criteria_met_all == false) {
          alert("Please check the UK Working Party Criteria.");
        }

        // Check the inclusion/exclusion criteria
        if (
          this.patient.meta_data.inclusion_criteria_0 == "No" ||
          this.patient.meta_data.inclusion_criteria_1 == "No" ||
          this.patient.meta_data.inclusion_criteria_2 == "No" ||
          this.patient.meta_data.inclusion_criteria_3 == "No" ||
          this.patient.meta_data.exclusion_criteria_0 == "Yes" ||
          this.patient.meta_data.exclusion_criteria_1 == "Yes"
        ) {
          alert(
            "Please check the inclusion / exclusion criteria. The patient is NOT eligible for the study."
          );
        }
      }

      ApiService.put("/users/patient/" + this.$route.params.id, {
        patientData: this.patient,
        new_password: this.new_password,
      })
        .then(() => {
          // Success
          alert("Success: Patient information updated.");
          this.new_password = "";
          this.refresh();
        })
        .catch((error) => {
          // Failure
          this.new_password = "";
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            this.error_msg = error.response.data.message;
          } else {
            this.error_msg =
              "An error occurred. Update patient information failed.";
          }
          alert(
            "An error has occurred. Update patient information failed. " +
              this.error_msg
          );
        });
    },
  },
};
</script>

<style>
input:invalid {
  border: 1px solid red;
}
select:invalid {
  border: 1px solid red;
}

.red-text {
  color: red;
}

.vdp-datepicker {
  &.fullscreen-when-on-mobile {
    @media (max-width: 767px) {
      position: static;
      .vdp-datepicker__calendar {
        position: fixed;
        top: 50%;
        left: 5%;
        margin-top: -25%;
        width: 90%;
      }
    }
  }
}
</style>
